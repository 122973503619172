
import { Options, mixins } from "vue-class-component"
import CommonPage from "@/mixins/CommonPage"
import Layout from "@/components/Layout.vue"
import Title from "@/components/Title.vue"
import { surveyDisclaimerEvent } from "@/api/services/EventService"
import { $vfm } from "vue-final-modal"
import SurveyTermsModal from "@/components/Modal/SurveyTermsModal.vue"
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal.vue"

@Options({
  components: {
    Layout,
    Title,
    SurveyTermsModal,
    PrivacyPolicyModal
  }
})
export default class SurveyDisclaimer extends mixins(CommonPage) {
  privacyPolicyModalName = "PrivacyPolicyModal"
  surveyTermsModalName = "SurveyTermsModal"

  get labels(): string[] {
    return [
      `This survey is exclusively for you ${this.contact?.fullNameCapitalized}, no sharing.`,
      "You’ll need to qualify for this survey before being eligible to complete it.",
      "Finish the survey as soon as possible, before spots run out.",
      "Read all questions carefully and answer thoroughly. We monitor quality."
    ]
  }

  onOpenSurveyTermsModal(): void {
    $vfm.show(this.surveyTermsModalName)
  }

  onOpenPrivacyPolicyModal(): void {
    $vfm.show(this.privacyPolicyModalName)
  }

  async onStart(): Promise<void> {
    await surveyDisclaimerEvent(this.id)

    if (this.invite?.panelist) {
      this.onAcceptInvite()
    } else {
      this.$router.push({
        name: "ConfirmName",
        query: {
          id: this.id
        }
      })
    }
  }
}
